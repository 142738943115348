body {
 /* background-color: rgb(243, 243, 229); */
 background-color: #eceee7;
}

img {
 width: 100%;
 height: auto;
 max-width: 100%;
 display: block;
 margin: 0 auto;
}

.msg {
 /* width: 1200px; */
 font-family:Arial, Helvetica, sans-serif ;
 font-size: 20px; 
 color: rgb(68, 68, 59);
 
}

.txt {
 /* background-color: lightgray; */
 font-weight: 600;
}

.header {
 color: rgb(68, 68, 59);
 display: flex;
 justify-content: center;

 font-family: Arial, Helvetica, sans-serif ;
 font-size: 25px;
 font-weight: 900;

}